import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/Header';
import Footer from '../common/footer/Footer';
import Copyright from '../common/footer/Copyright';
import About1 from '../elements/about/About';
import ServiceFive from '../elements/service/ServiceFive';
import CalltoAction1 from '../elements/calltoaction/CalltoAction1';
import OurTeam from '../elements/team/OurTeam';
import Testimonials from "../elements/testimonial/Testimonials";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import Mission from "../elements/about/Mission";
import StringTyped from '../components/common/stringTyped';

const Home = () => {
    return (
        <>
            <SEO title="CloudTech Next" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="round" HeaderSTyle="header-not-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent height-850">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">CloudTech Next</span>
                                    <h1 className="title theme-gradient display-two">Our portofolio: <br /> {" "}
                                        <StringTyped
                                            strings={[
                                                "IT Consulting.",
                                                "Cloud Computing Consulting.",
                                                "Maintenance serviceses",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">We're here to ensure your success! 
                                    At CloudTech Next, our mission is to empower as our customer with tailored solutions, expert guidance, 
                                    and unwavering support. Let's achieve greatness together!</p>
                                    <div className="button-group">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start About Area  */}
                <About1 />                            
                {/* End About Area  */}

                <Separator />                            
                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you?"
                                    title = "Services we provide for you."
                                    description = "We can provide a range of services related to cloud computing and migration."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                                serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}


                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        <CalltoAction1 />
                    </div>
                </div>
                {/* End Call To Action Area  */}


                 {/* Start Team Area  */}
                 <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our Expert."
                                        title = "Our Company Expert."
                                        description = "We provide cloud computing consultancy services for <br /> startups and company businesses."
                                    />
                            </div>
                        </div>
                        <OurTeam column="col-lg-4 col-md-6 col-20 order-2" teamStyle="team-style-default style-one" />
                    </div>
                </div>
                {/* End Team Area  */}
                <Separator /> 
                {/* Start Mission Area   */}
                <Mission />                    
                {/* Start Mission Area  */}


                <Separator />                               
                {/* Start Testimonial Area  */}
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Client Feedback"
                                    title = "Our Awesome Clients."
                                    description = "We provide cloud computing consultancy services for <br /> startups and company businesses."
                                />
                            </div>
                        </div>
                        <Testimonials teamStyle="" />
                    </div>
                </div>
                {/* End Testimonial Area  */}

                <Separator />   

                <Footer />
                <Copyright />
            </main>
        </>
    )
}
export default Home;
