import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import Breadcrumb from "../breadcrumb/Breadcrumb";
import SectionTitle from "../sectionTitle/SectionTitle";
import OurTeam from "./OurTeam";

const Team = () => {
    return (
        <>
            <SEO title="CloudTech Next" />
            <Layout>
                <Breadcrumb
                    title="Meet our skilled team for your cloud computing business needs."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Team"
                />
                <div className="main-content">

                    {/* Start Elements Area  */}
                    <div className="rwt-team-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                        <SectionTitle
                                            textAlign = "text-center"
                                            radiusRounded = ""
                                            subtitle = "Our Experts."
                                            title = "Our Expert Team."
                                            description = ""
                                        />
                                </div>
                            </div>
                            <OurTeam column="col-lg-4 col-md-6 col-12 mt--30" teamStyle="team-style-default" />
                        </div>
                    </div>
                    {/* End Elements Area  */}
                    

                </div>
            </Layout>
        </>
    )
}
export default Team;