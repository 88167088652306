import React from 'react';
import {Link} from "react-router-dom";

export default function Nav() {
    return (
        <ul className="mainmenu">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/team">Our Team</Link></li>
            <li><Link to="/about-us">About Us</Link></li>
        </ul>
    )
}
