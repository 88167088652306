import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "@stromsky/react-animate-on-scroll";

const ServiceList = [
    {
        icon: '1',
        title: 'Cloud Migration Services',
        description: 'Help businesses migrate their applications, data, and workloads to cloud platforms such as Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), and others.'
    },
    {
        icon: '2',
        title: 'Cloud Strategy and Planning',
        description: 'Work closely with clients to develop a comprehensive cloud strategy that aligns with their business objectives and IT goals.'
    },
    {
        icon: '3',
        title: 'Infrastructure Design and Optimization',
        description: 'Design cloud infrastructure that is scalable, secure, and cost-effective. Provide ongoing optimization to ensure efficiency and cost savings.'
    },
    {
        icon: '4',
        title: 'Cloud Security',
        description: 'Implement robust security measures and best practices to protect data and applications in the cloud, including identity and access management, encryption, and compliance.'
    },
    {
        icon: '5',
        title: 'Managed Cloud Services',
        description: 'Offer ongoing management and support for cloud environments, including monitoring, maintenance, and troubleshooting.'
    },
    {
        icon: '6',
        title: 'Data Migration and Management',
        description: 'Assist with migrating and managing data in the cloud, including data storage, backup, and retrieval.'
    },
    {
        icon: '7',
        title: 'Containerization and Orchestration',
        description: 'Help clients leverage container technologies like Docker and Kubernetes for improved application deployment and scalability.'
    },
    {
        icon: '8',
        title: 'Serverless Computing',
        description: 'Develop and deploy serverless applications, allowing clients to focus on code rather than infrastructure management.'
    },
    {
        icon: '9',
        title: 'Hybrid Cloud Solutions',
        description: 'Create hybrid cloud environments that seamlessly integrate on-premises and cloud-based resources for flexibility and efficiency.'
    },
    {
        icon: '10',
        title: 'Cost Optimization',
        description: 'Analyze cloud spending and provide strategies to optimize costs without sacrificing performance or security.'
    },
    {
        icon: '11',
        title: 'DevOps and Automation',
        description: 'Implement DevOps practices and automation tools to streamline development, testing, and deployment processes.'
    },
    {
        icon: '12',
        title: 'Cloud Governance',
        description: 'Establish governance frameworks to manage cloud resources efficiently and ensure compliance with industry regulations.'
    }
]
const ServiceFive = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="content">
                                    <h4 className="title">
                                        <Link to="/contact" dangerouslySetInnerHTML={{__html: val.title}}></Link>
                                    </h4>
                                    <p className="description" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                                <div className="image">
                                    {/* <img className="img-fluid" src={`${val.image}`} alt="card Images" /> */}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceFive;