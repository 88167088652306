import React from 'react'
import SEO from "../common/SEO";
import HeaderOne from '../common/header/Header';
import Footer from '../common/footer/Footer';
import ServiceFive from "../elements/service/ServiceFive";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUp from "../elements/counterup/CounterUp";
import Timeline from "../elements/timeline/Timeline";
import Separator from "../elements/separator/Separator";
import OurTeam from '../elements/team/OurTeam';

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 height-850 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/bg-image-12.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner pt--80 text-center">
                                    <div>
                                        <h3 className="rn-sub-badge"><span className="theme-gradient">About us</span></h3>
                                    </div>
                                    <h1 className="title display-one">We are small startup <br /> IT consultancy.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">About us</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">At CloudTech Next, we believe in the power of transformation through technology. We are a forward-thinking team of cloud computing experts dedicated to helping businesses navigate the complexities of the digital age. Our mission is to empower you with the tools, knowledge, and support needed to thrive in an increasingly digital world.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Who We Are</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">CloudTech Next is not just a service provider; we're your strategic partner in the cloud journey. Our team comprises professionals with a passion for technology, a deep understanding of cloud computing, and a commitment to your success. We bring a wealth of experience in cloud migration, infrastructure design, security, and optimization to the table.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                <Separator />
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Our Approach</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10">Every business is unique, and so are its challenges and opportunities. That's why we take a personalized approach to every project. We work closely with you to understand your specific needs, goals, and constraints. Our solutions are tailor-made to align perfectly with your vision.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
                
                <Separator />
                <div className="service-area rn-section-gapTop">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="content">
                                    <h3 className="title">Why choosing us?</h3>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <p className="mb--10"><b>Expertise:</b><br/>Our team consists of certified experts with a proven track record in cloud computing. We keep pace with the latest industry trends and technologies to deliver cutting-edge solutions.</p>
                                <p className="mb--10"><b>Security and Compliance:</b><br/> Data security and compliance are at the core of everything we do. We ensure that your cloud environment is secure and compliant with relevant regulations.</p>
                                <p className="mb--10"><b>Cost Optimization:</b><br/> We don't just migrate you to the cloud; we help you optimize costs, making the most of your cloud investment.</p>
                                <p className="mb--10"><b>Ongoing Support:</b><br/>Our commitment doesn't end with migration. We provide continuous support, monitoring, and maintenance to ensure the long-term success of your cloud infrastructure.</p>
                                <p className="mb--10"><b>Education and Training:</b> <br/>We empower your team with the knowledge and skills needed to manage and innovate in the cloud independently.</p>
                            
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}
                {/* Start Brand Area  */}
{/*                 <div className="rwt-brand-area pb--60 pt--30">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                <BrandThree brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* End Brand Area  */}


                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "What we can do for you?"
                                    title = "Services provide for you."
                                    description = "We can provide a range of services related to cloud computing and migration."
                                    />
                            </div>
                        </div>
                        <ServiceFive 
                                serviceStyle = "service__style--1 icon-circle-style with-working-process"
                                textAlign = "text-center"
                            />
                    </div>
                </div>
                
                <Separator />
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Our project timelines."
                                        title = "Possible changes in timeline can be expected."
                                        description = ""
                                    />
                            </div>
                        </div>
                        <Timeline classVar="no-gradient"  />
                    </div>
                </div>

                <Separator />
                
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area pb--100">
                    <div className="container mt_dec--30">
                        <CounterUp column="col-lg-3 col-md-6 col-sm-6 col-12" counterStyle="counter-style-4" textALign="text-center" />   
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />

                {/* Start Elements Area  */}
                <div className="rwt-team-area rn-section-gap">
                    <div className="container">
                        <div className="row mb--20">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Our Corporate Team."
                                    title = "Corporate Team Member."
                                    description = ""
                                />
                            </div>
                        </div>
                        <OurTeam column="col-lg-4 col-md-6 col-20 order-2" teamStyle="team-style-default style-one" />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Footer />
            </main>
        </>
    )
}

export default AboutUs;
