import React from 'react';
import emailjs from '@emailjs/browser';
import Recaptcha from 'react-recaptcha';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          recaptchaLoad: false,
          isVerified: false,
          result: false,
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        };
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifiedRecaptcha = this.verifiedRecaptcha.bind(this);
        this.endResult = this.endResult.bind(this);
      }
    

    handleSubmit(event) {
        const { recaptchaLoad, isVerified } = this.state;
        event.preventDefault();
        
        if (recaptchaLoad && isVerified) {
            const { firstname, lastname, email, phone, subject, message } = this.state;
            const templateParams = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                phone: phone,
                to_name: 'contact@cloudtechnext.com',
                subject,
                message: message,
            };
            emailjs.send(
                'default_service',
                'template_wl63q5j',
                templateParams,
                'sqj3GOKrmKDFvkfav',
                );
                this.resetForm();
                window.grecaptcha.reset();         
        } else {
            alert('');
        }
    };
    
    
    endResult() {
        this.setState({ result: true})
    }

    resetForm() {
        this.setState({
        recaptchaLoad: false,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
        });
    }

    recaptchaLoaded() {
        this.setState({ recaptchaLoad: true });
    }
      
    verifiedRecaptcha(response) {
        if (response) {
          this.setState({ isVerified: true });
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render () {
        const { firstname, lastname, email, phone, subject, message, endResult } = this.state;

        return (
<           form data-badge="inline" onSubmit={this.handleSubmit}>
            <h3>Contact us</h3>
            <div className="form-group">
                <input 
                type="text"
                onChange={this.handleChange}
                name="firstname"
                value={firstname}
                placeholder="Your first name"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                onChange={this.handleChange}
                name="lastname"
                value={lastname}
                placeholder="Your last name"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="email"
                onChange={this.handleChange}
                name="email"
                value={email}
                placeholder="Email Address"
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="tel"
                onChange={this.handleChange}
                name="phone"
                value={phone}
                placeholder="Phone Number"
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="text"
                name="subject"
                onChange={this.handleChange}
                value={subject}
                placeholder="Subject"
                required
                />
            </div>

            <div className="form-group">
                <textarea 
                name="message"
                onChange={this.handleChange}
                value={message}
                placeholder="Your Message"
                required
                >
                </textarea>
            </div>
            <div className='form-group'>
            <Recaptcha
                ref={e => (this.captcha = e)}
                sitekey="6Le-qDYoAAAAANfFLgslYSmCZlQpgAEmzToZLFCV"
                render="explicit"
                onloadCallback={this.recaptchaLoaded}
                verifyCallback={this.verifiedRecaptcha}
                theme='dark'
            />
            </div>

            <div className="form-group">
                <button className="btn-default btn-large" disabled={!this.state.isVerified}>Submit Now</button>
            </div>
            
            <div className="form-group">
                {endResult ? <Result /> : null}
            </div> 
        </form>
       )
    }
}

export default ContactForm;
