import React from 'react'

export default function Copyright() {
    return (
        <div className="copyright-area copyright-style-one">
            <div className="container">
                <div className="row text-center">
                    <div className="col-5 order-0">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover text-left">
                                <li><a href="/policy">Privacy Policy</a></li>
                                <li><a href="/toc">Terms And Condition</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <div className="copyright-center">
                            <p className="copyright-text text-center">VAT NR. : <b>GB430667696</b></p>
                        </div>
                    </div>
                    <div className="col">
                        <div className="copyright-right text-center text-md-end">
                            <p className="copyright-text">© CloudTech Next 2022 - {new Date().getFullYear()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}