import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import PageScrollTop from './components/pageToTop/PageScrollTop'

import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Team from "./elements/team/Team";
import Contact from "./elements/contact/Contact";


// Import Css Here 
import './assets/scss/style.scss';
import Home from './pages/Home';

//Cognito shit
import { Amplify } from 'aws-amplify';
import config from './aws-exports';
import PrivacyPolicy from './pages/Privacy';
Amplify.configure(config);

function App( {singOut}) {
    return (
        <Router>
            <PageScrollTop>
                <Routes>
                    <Route path={"/"} element={<Home />}/>

                    <Route path={"/team"} element={<Team />}/>

                    <Route path={"/about-us"} element={<AboutUs />}/>

                    <Route path={"/contact"} element={<Contact />}/>

                    <Route path={"/policy"} element={<PrivacyPolicy />} />

                </Routes>
            </PageScrollTop>
        </Router>
    )
}
export default App;
