import React from 'react';
import Slider from "react-slick";
import { slickDot } from "../../utils/script";

const TestimonialData = [
    {
        form: "United Kingdom",
        description: "CloudTech Next exceeded our expectations with their cloud migration services. Their team's expertise and commitment to our project ensured a seamless transition. Thanks to them, our business is now more agile and cost-efficient.",
        name: "Gregory L Burgher",
        subtitle: "Chief Executive Officer",
        image: "testimonial-dark-01"
    },
    {
        form: "Romania",
        description: "CloudTech Next provided our team with comprehensive training, making us self-sufficient in managing our cloud environment. Their commitment to knowledge transfer has empowered us to maximize the benefits of the cloud.",
        name: "Roxana M. Badea",
        subtitle: "IT Manager at Manufacturing Pro",
        image: "testimonial-dark-02"
    },
    {
        form: "Italy",
        description: "CloudTech Next's support and expertise helped us create a hybrid cloud environment that perfectly suits our educational institution's needs. Their team's responsiveness and dedication to our project made all the difference.",
        name: "Baldo Padovesi",
        subtitle: "IT Director at Educational Institute",
        image: "testimonial-dark-03"
    },
]
const Testimonials = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickDot}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/testimonial/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default Testimonials;




















