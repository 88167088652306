import React from 'react';
import StringTyped from "../../components/common/stringTyped";
export default function About() {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="./images/about/about-1.png" alt="About Images" />
                        </div>
                    </div>
                    
                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">CloudTech Next <br /> {" "}
                                   <StringTyped></StringTyped>
                                </h2>
                                <p>CloudTech Next is a pioneering force in cloud migration solutions, dedicated to guiding businesses 
                                    towards success in the ever-evolving digital landscape. 
                                    With a dynamic team of seasoned experts, we specialize in orchestrating seamless transitions to the cloud.</p>
                                <p>Our mission revolves around empowering clients with tailored, forward-thinking strategies, leveraging the latest cloud technologies, and delivering unwavering support at every step. 
                                    We understand that every business is unique, which is why we take a personalized approach to ensure that your migration aligns perfectly with your objectives. </p>
                                <p>At CloudTech Next, we're committed to optimizing your operations, reducing costs, and fostering innovation. 
                                    With a focus on scalability, security, and efficiency, we provide the tools and expertise needed to unlock your full potential in the cloud.</p>
                                <p>As a trusted leader in the industry, we've helped numerous clients achieve transformative results, and we're ready to do the same for you. 
                                    Join us on a journey towards success in the digital age and experience the unparalleled support and expertise that define as future customer.</p>
                                <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="/about-us"><span>More About Us</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}
