import React from 'react'

export default function Blury() {
    return (
        <div>
            <div className="rn-gradient-circle"></div>
            <div className="rn-gradient-circle theme-pink"></div>
        </div>
    )
}
