import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log('totally custom!'),
    );
  
    return (
      <button className="btn btn-link shadow-none"
        type="button"
        style={{ backgroundColor: 'transparent' }}
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

export default function AccordionFunc({customStyle}) {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                <CustomToggle eventKey="0">
                    What is CloudTech Next?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>CloudTech Next is a leading provider of cloud migration solutions and services. We specialize in helping businesses seamlessly transition to cloud environments such as AWS, Azure, and Google Cloud. Our team of experts offers end-to-end solutions, from strategic planning to execution, to optimize the benefits of cloud technology for our clients. </Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="1">
                    Why should I consider migrating to the cloud?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                    <Card.Body>Cloud migration offers several advantages, including scalability to accommodate growth, cost-efficiency by eliminating the need for on-premises hardware, and flexibility to adapt to changing business demands. It also enhances accessibility, security, and collaboration capabilities.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="2">
                Which cloud platforms do you work with?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                    <Card.Body>We have expertise in working with major cloud service providers, including Amazon Web Services (AWS), Microsoft Azure, Google Cloud Platform (GCP), and others. Our team can recommend the best platform for your specific needs.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="3">
                How can CloudTech Next help my business with cloud migration?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>We provide comprehensive cloud migration services, including initial assessments, strategy development, data migration, application modernization, and ongoing support. 
                        Our experienced team ensures a smooth and successful migration tailored to your unique requirements.</Card.Body>
                </Accordion.Collapse>
            </Card>
            
            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="4">
                What industries do you serve?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                    <Card.Body>WWe work with a diverse range of industries, including finance, healthcare, manufacturing, retail, and more. 
                        Our adaptable solutions are designed to meet the specific challenges and opportunities of each industry.</Card.Body>
                </Accordion.Collapse>
            </Card>
            
            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="5">
                Do you offer customized solutions?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                    <Card.Body>Yes, our approach is highly customized. We understand that every business has unique needs, and we tailor our cloud solutions to align with your goals, budget, and existing infrastructure.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="6">
                Is my data safe in the cloud?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                    <Card.Body>Data security is our top priority. We implement industry-leading security measures, including encryption, access controls, and continuous monitoring, to protect your data in the cloud. We also ensure compliance with relevant data protection regulations.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="7">
                How do you ensure compliance with industry regulations?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="7">
                    <Card.Body>Our team is well-versed in industry-specific regulations, such as HIPAA, GDPR, and PCI DSS. We design and implement cloud solutions that align with these regulations, providing you with a compliant environment.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="8">
                What is the typical timeline for a cloud migration project?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="8">
                    <Card.Body>The timeline for a cloud migration project can vary depending on the complexity and size of your organization. Generally, a migration project can take several weeks to several months. We work closely with you to establish a clear timeline and keep you informed throughout the process.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="9">
                What are your pricing structures and payment options?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="9">
                    <Card.Body>Our pricing is tailored to the specific services and resources you require. We offer flexible pricing models, including fixed-price contracts, pay-as-you-go options, and subscription-based plans. Our goal is to provide cost-effective solutions that align with your budget.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="10">
                Do you provide ongoing support and maintenance?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="10">
                    <Card.Body>Yes, we offer post-migration support and maintenance services. Our team provides continuous monitoring, optimization, troubleshooting, and updates to ensure the stability and performance of your cloud environment.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="11">
                Can you share success stories or case studies?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="11">
                    <Card.Body>Absolutely! We have a portfolio of successful cloud migration projects across various industries. Our case studies and success stories demonstrate the tangible benefits our clients have realized through our services.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="12">
                How do I get started with CloudTech Next?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="12">
                    <Card.Body>Getting started is easy. Simply reach out to our team through our website or contact information. We'll schedule an initial consultation to discuss your specific needs and objectives and determine the best approach for your cloud migration journey.</Card.Body>
                </Accordion.Collapse>
            </Card>

            <Card>
                <Card.Header>
                <CustomToggle variant="link" eventKey="13">
                What sets CloudTech Next apart from other cloud service providers?
                </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="13">
                    <Card.Body>What sets us apart is our dedication to personalized service, expertise, and commitment to your success. We don't just migrate businesses to the cloud; we partner with them to achieve their goals and navigate the complexities of cloud technology effectively.</Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

