import React from 'react';
import ScrollAnimation from "@stromsky/react-animate-on-scroll";

const TimelineData = [
    {
        id: "1",
        title: "Contact",
        description: "Customer reaches out; initial discussion and service overview.",
    },
    {
        id: "2",
        title: "Proposal",
        description: "Needs assessment, tailored proposal, review, and acceptance.",
    },
    {
        id: "3",
        title: "Kickoff",
        description: "Project planning, team formation, and milestone definition.",
    },
    {
        id: "4",
        title: "Implementation",
        description: "Migration, setup, testing, and ongoing optimization.",
    },
    {
        id: "5",
        title: "Training",
        description: "Knowledge transfer and IT team training if included.",
    },
    {
        id: "6",
        title: "Support",
        description: "Continuous support, monitoring, and maintenance.",
    },
    {
        id: "7",
        title: "Completion",
        description: "Project review, evaluation, and feedback collection.",
    },
    {
        id: "8",
        title: "Post-Project",
        description: "Ongoing relationship, guidance, and industry updates.",
    },
]

const Timeline = ({classVar}) => {
    return (
        <div className="timeline-style-two bg-color-blackest">
            <div className="row row--0">
                {/* Start Single Progress */}
                {TimelineData.map((data , index) =>(
                    <div className={`col-lg-3 col-md-3 rn-timeline-single ${classVar}`} key={index}>
                        <div className="rn-timeline">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h6 className="title">{data.title}</h6>
                            </ScrollAnimation>
                            <div className="progress-line">
                                <div className="line-inner"></div>
                            </div>
                            <div className="progress-dot">
                                <div className="dot-level">
                                    <div className="dot-inner"></div>
                                </div>
                            </div>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <p className="description">{data.description}</p>
                            </ScrollAnimation>
                        </div>
                    </div>
                ))}
                {/* End Single Progress */}
            </div>
        </div>
    )
}
export default Timeline;