import React from 'react';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionFunc from '../accordion/Accordion';
import StringTyped from '../../components/common/stringTyped';

export default function mission() {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Our unique missions and questions may have <br /> {" "}
                                <StringTyped className="theme-gradient"
                                    strings={[
                                        "Empowering Businesses to Reach New Heights in the Cloud",
                                        "Navigating Your Journey to Cloud Excellence",
                                        "Transforming Today's Challenges into Tomorrow's Cloud Success Stories",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>The first mission statement reflects your commitment to helping businesses not only migrate to the cloud but also harness its full potential for growth, innovation, and success. It emphasizes your focus on empowerment, partnership, and the transformative power of cloud technology.</p>

                            <p>The second mission statement underscores our role as a guiding partner in your cloud journey. It communicates our commitment to helping your business navigate the complexities of cloud technology while striving for excellence and optimization every step of the way.</p>

                            <p>The third mission statement highlights our company's dedication to helping businesses overcome their current challenges by embracing cloud solutions. It conveys your commitment to turning obstacles into opportunities and assisting our clients in creating success stories in the cloud.</p>
                            <div className="read-more-btn mt--50">
                                <Link className="btn-default btn-icon" to="/contact">Contact us <i className="icon"><FiArrowRight /></i></Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <AccordionFunc customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}