// PrivacyPolicy.js
import React from 'react';
import SEO from "../common/SEO";
import HeaderOne from '../common/header/Header';
import Footer from '../common/footer/Footer';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import Copyright from '../common/footer/Copyright';

const PrivacyPolicy = () => {
  return (
    <>
    <SEO title="Privacy Policy" />
    <main className="page-wrapper">
        <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
            {/* Start policy area */}
            <div className="rwt-privacy-title-area rn-section-gap">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mb--40">
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Privacy Policy"
                                title = "Our privacy policy"
                                description = ""
                            />
                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                This Privacy Policy describes the policies of 
                                CloudTech Next Ltd.,
                                26 Culworth Court,
                                England
                                CV6 5JY,
                                United Kingdom of Great Britain and Northern Ireland (the),
                                email: contact@cloudtechnext.com,
                                phone: +442476761295

                                on the collection,
                                use and disclosure of your information that we collect
                                when you use our website (<a href='https://cloudtechnext.com'>comercial domain</a> or <a href='https://cloudtechnext.co.uk'>localized domain</a>).
                                (the “Service”). By accessing or using the 
                                Service, you are consenting to the collection, use and
                                disclosure of your information in accordance with this 
                                Privacy Policy. If you do not consent to the same, 
                                please do not access or use the Service.
                            </p>
                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                            We may modify this Privacy Policy at any time without
                            any prior notice to you and will post the revised
                            Privacy Policy on the Service. The revised Policy will
                            be effective 180 days from when the
                            revised Policy is posted in the Service and your
                            continued access or use of the Service after such time
                            will constitute your acceptance of the revised Privacy
                            Policy. We therefore recommend that you periodically
                            review this page.
                            </p>

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Information we collect:"
                                title = ""
                                description = " We will collect and process the following personal information about you:"
                            />
                            
                            <div class="text-center" style={{listStyleType: "inside"}}>                   
                                <font size="+1">
                                        <li>Name</li>
                                        <li>Email</li>
                                        <li>Mobile</li>
                                        <li>Date of Birth</li>
                                        <li>Address</li>
                                        <li>Payment Info</li>
                                </font>
                            </div>
                            <div><p></p></div>
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "How We Use Your Information:"
                                description = "We will use the information that we collect about you for the following purposes:"
                            />
                            <div class="text-center" style={{listStyleType: "inside"}}>                   
                                <font size="+1">
                                    <li>Customer feedback collection</li>
                                    <li>Enforce T&C</li>
                                    <li>Processing payment</li>
                                    <li>Support</li>
                                    <li>Manage customer order</li>
                                    <p></p>
                                </font>
                            </div>

                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                If we want to use your information for any other
                                purpose, we will ask you for consent and will
                                use your information only on receiving your
                                consent and then, only for the purpose(s) for
                                which grant consent unless we are required to do
                                otherwise by law.
                            </p>

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Retention Of Your Information:"
                                description = ""
                            />

                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                We will retain your personal information with us
                                for 90 days to 2 years after users terminate their accounts
                                or for as
                                long as we need it to fulfill the purposes for
                                which it was collected as detailed in this
                                Privacy Policy. We may need to retain certain
                                information for longer periods such as
                                record-keeping / reporting in accordance with
                                applicable law or for other legitimate reasons
                                like enforcement of legal rights, fraud
                                prevention, etc. Residual anonymous information
                                and aggregate information, neither of which
                                identifies you (directly or indirectly), may be
                                stored indefinitely.
                            </p>

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Your Rights:"
                                description = ""
                            />

                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                Depending on the law that applies, you may have
                                a right to access and rectify or erase your
                                personal data or receive a copy of your personal
                                data, restrict or object to the active
                                processing of your data, ask us to share (port)
                                your personal information to another entity,
                                withdraw any consent you provided to us to
                                process your data, a right to lodge a complaint
                                with a statutory authority and such other rights
                                as may be relevant under applicable laws. To
                                exercise these rights, you can write to us at
                                contact@cloudtechnext.com.
                                We will respond to your
                                request in accordance with applicable law.
                            </p>

                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                Do note that if you do not allow us to collect
                                or process the required personal information or
                                withdraw the consent to process the same for the
                                required purposes, you may not be able to access
                                or use the services for which your information
                                was sought.
                            </p>

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Cookies Etc."
                                description = "To learn more about how we use theseand your choices in relation to these tracking technologies, please refer to our <a href=https://cloudtechnext.com> Cookie Policy.</a>"
                            />
                            
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Security:"
                                description = ""
                            />
                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                The security of your information is important to
                                us and we will use reasonable security measures
                                to prevent the loss, misuse or unauthorized
                                alteration of your information under our
                                control. However, given the inherent risks, we
                                cannot guarantee absolute security and
                                consequently, we cannot ensure or warrant the
                                security of any information you transmit to us
                                and you do so at your own risk.
                            </p>

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Third Party Links & Use Of Your Information:"
                                description = ""
                            />

                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                Our Service may contain links to other websites
                                that are not operated by us. This Privacy Policy
                                does not address the privacy policy and other
                                practices of any third parties, including any
                                third party operating any website or service
                                that may be accessible via a link on the
                                Service. We strongly advise you to review the
                                privacy policy of every site you visit. We have
                                no control over and assume no responsibility for
                                the content, privacy policies or practices of
                                any third party sites or services.
                            </p>

                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Grievance / Data Protection Officer:"
                                description = ""
                            />

                            <p class="privacy-policy-p" style={{textIndent: '60px'}}>
                                If you have any queries or concerns about the
                                processing of your information that is available
                                with us, you may email our Grievance Officer at
                                CloudTech Next Ltd.,
                                26 Culworth Court,
                                email: contact@cloudtechnext.com.
                                We will address your concerns in accordance with applicable law.
                            </p>
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Validity dates:"
                                description = "<h5>Last Updated On 21-Sep-2023</h5><h5>Effective Date 21-Sep-2023</h5>"
                            />
                        </div>
                    </div>
                </div>
            </div>

        <Footer />

        <Copyright/>
    </main>
        </>
  );
};

export default PrivacyPolicy;