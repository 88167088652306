import React from 'react';
import { FiFacebook, FiTwitter, FiLinkedin } from "react-icons/fi";
import ScrollAnimation from "@stromsky/react-animate-on-scroll";

const teamData = [
    {
        image: 'https://media.licdn.com/dms/image/D4E03AQH-s6oEpA93FQ/profile-displayphoto-shrink_200_200/0/1695000698747?e=1700697600&v=beta&t=tK22U3g72Omvsw_YcLMuXR9fsSG3BMfz6wC-eDUf8mM',
        name: 'Stefan-Robert Ursu',
        designation: 'Chief Executive Officer',
        location: 'Coventry, UK', 
        description: `As the owner of CloudTech Next and a cloud computing expert, I am committed to providing top-notch cloud migration solutions to businesses. With a passion for technology and a dedication to excellence, I strive to help my clients achieve their goals and succeed in the digital age.`,
        socialNetwork: [
            {
                icon: <FiFacebook />,
                url: 'https://www.facebook.com/ursustefanrobert'
            },
            {
                icon: <FiLinkedin />,
                url: 'https://www.linkedin.com/in/stefan-robert-ursu-b801a6287/'
            },
            {
                icon: <FiTwitter />,
                url: 'https://twitter.com/StefanRobertUr1'
            },
        ]
       
    }
]


const OurTeam = ({column , teamStyle}) => {
    return (
        <div className="row d-flex justify-content-center row--15">
            {teamData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`rn-team ${teamStyle}`}>
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`${data.image}`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <span className="team-form">
                                        <img src="./images/team/location.svg" alt="Corporate React Template" />
                                        <span className="location">{data.location}</span>
                                    </span>
                                    <p className="description text-center">{data.description}</p>

                                    <ul className="social-icon social-default icon-naked mt--20" >
                                        {data.socialNetwork.map((social, index) =>
                                            <li key={index}><a href={`${social.url}`}>{social.icon}</a></li>
                                        )}
                                    </ul>
                                </figcaption>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default OurTeam;
